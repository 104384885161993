import React from 'react';
import "./terms.css";
import Footer from '../../layout/Footer/Footer';
import Navbar from '../../layout/Navbar/Navbar';

const TermsAndConditions = () => {
    return (
        <>
            <Navbar type="fixed" bcolor="#342f30" />
            <div className="terms_container">
                <h1 className="terms_heading">BestPropDeal Terms and Conditions</h1>
                <p>
                    Thank you for visiting the BestPropDeal website <b>(<a href="www.bestpropdeal.com">"www.bestpropdeal.com"</a>)</b>.
                    Before continuing to explore our platform, please carefully review the
                    following terms and conditions <b>("Terms")</b>. By accessing and utilizing this
                    site, you acknowledge your agreement to be bound by these Terms. If any
                    part of these Terms is not agreeable to you, please refrain from using the
                    site. For inquiries or concerns related to these Terms, feel free to contact
                    us at <b>support@bestpropdeal.com</b>.
                    {/* ... Rest of your Terms and Conditions content here ... */}
                </p>
                <h2 className='terms_subheading'>Disclaimer Regarding Accuracy, Errors, or Inaccuracies</h2>
                <p>
                    While <b>BestPropDeal</b> endeavors to ensure the accuracy of the information presented on this website, we do not warrant its correctness. We make no guarantees concerning the completeness or precision of the information.
                </p>
                <h2 className='terms_subheading'>Copyrighted Material</h2>
                <p>
                The material on this website, including text, graphics, and images, is protected by copyright and intellectual property rights. All rights are either owned by <b>BestPropDeal</b> or licensed to BestPropDeal. Users are granted a limited, personal, non-exclusive, and non-transferable license for personal use.
                </p>
                <h2 className='terms_subheading'>Trademarks</h2>
                <p>
                <b>BestPropDeal</b>'s trademarks may only be used in connection with BestPropDeal's products or services and for the purposes for which they are authorized.
                </p>
                <h2 className='terms_subheading'>General Terms and Conditions</h2>
                <p>
                <b>BestPropDeal</b> reserves the right to monitor user postings on the website. While this is not done routinely, we may respond to inappropriate use at our discretion. Users are responsible for their actions, and <b>BestPropDeal</b> may report illegal activities to law enforcement.
                </p>
                <p>
                Unauthorized use of materials on this website may violate various laws, and users are solely responsible for their actions. BestPropDeal reserves the right to terminate access to the website without notice for any breach of these Terms.
                </p>
                <p>
                These Terms may be updated periodically, and users are bound by the current version.
                </p>
                <h2 className='terms_subheading'>Applicable Law and Jurisdiction</h2>
                <p>
                These Terms and Conditions are governed by the laws of India. In the event of any dispute, you agree to submit to the exclusive jurisdiction of the courts located in Mumbai, India.
                </p>
                <h2 className='terms_subheading'>Do Not Disturb (DND)</h2>
                <p>
                We are authorized to make calls to customers who have signed up on our system, done enquiry to various media like Paper ads, property Portal, Digital Media. We respect your privacy and are committed to protecting the information you provide to us. Any personal data collected through this website is handled in accordance with our Privacy Policy. By using this website, you consent to the collection and use of information as outlined in our Privacy Policy.
                </p>
                <h2 className='terms_subheading'>General Information</h2>
                <ul>
                    <li>
                        <p>This document serves as an electronic record under the Information Technology Act, 2000, and does not require any physical or digital signatures.</p>
                    </li>
                    <li>
                        <p>These Terms are published in compliance with the Information Technology (Guidelines for Intermediaries and Digital Media Ethics Code) Rules, 2021.</p>
                    </li>
                    <li>
                        <p>The Site is operated by <b>Bestpropdeal</b>, a company registered under the Companies Act, 2013.</p>
                    </li>
                    <li>
                        <h4>Definitions:</h4>
                        <ul>
                            <li>
                                <p>
                                <b>User: </b>Any individual accessing or using the Site, capable of entering into contracts as per the Indian Contract Act, 1872.
                                </p>
                            </li>
                            <li>
                                <p>
                                <b>We/Us/Our: </b>Refers to Bestpropdeal.
                                </p>
                            </li>
                            <li>
                                <p>
                                <b>Parties: </b>Collectively refers to the User and Bestpropdeal.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>Section titles are used for organization only and do not impact the interpretation of the Terms.</p>
                    </li>
                    <li>
                        <p>Your use of the Site indicates your acceptance of these Terms and our Privacy Policy. Accessing the Site means you agree to be bound by these terms.</p>
                    </li>
                    <li>
                        <p>These Terms, along with the Privacy Policy, constitute a binding agreement between the User and Bestpropdeal. Your continued use of the Site signifies acceptance without needing a signature.</p>
                    </li>
                    <li>
                        <p>Bestpropdeal reserves the right to modify these Terms at any time. Continued use of the Site after such changes indicates acceptance. Users are encouraged to review the Terms regularly.</p>
                    </li>
                </ul>
                <h2 className='terms_subheading'>Services</h2>
                <ul>
                    <li>
                        <p><b>Property Identification: </b>Customers can search and finalize properties from an extensive portfolio using digital and expert solutions tailored to their needs. Properties are listed by developers partnering with Bestpropdeal to ensure a seamless experience.</p>
                    </li>
                    <li>
                        <p><b>Transactional Support: </b>Our sales team provides assistance with site visits, property comparisons, and pricing/inventory negotiations.</p>
                    </li>
                    <li>
                        <p><b>Financial & Administrative Support: </b>We offer guidance on mortgage, loans, and other financial services through our partners, as well as legal and administrative support.</p>
                    </li>
                    <li>
                        <p><b>Other Allied Services: </b>Additional services like packing, moving, interior designing, and furnishing are facilitated through business partners.</p>
                    </li>
                    <li>
                        <p><b>Product Portfolio: </b>We cater to various property types, including residential, commercial, land, global projects, and more.</p>
                    </li>
                    <li>
                        <p><b>Portfolio Structuring & Management: </b>We assist in diversifying real estate portfolios globally, offering attractive investment options to Indian investors.</p>
                    </li>
                    <li>
                        <p><b>Service for Life: </b>Customers may be assigned a "Dedicated Relationship Manager" for lifetime assistance.</p>
                    </li>
                </ul>
                <h2 className='terms_subheading'>User Obligations</h2>
                <p>Users agree to:</p>
                <ul>
                    <li>
                        <p>Not reproduce, copy, or modify Website content without permission.</p>
                    </li>
                    <li>
                        <p>Access the Website only through the provided interface.</p>
                    </li>
                    <li>
                        <p>Avoid using automated tools to extract data or interfere with the Website.</p>
                    </li>
                    <li>
                        <p>Ensure uploaded content is lawful and not offensive.</p>
                    </li>
                    <li>
                        <p>Refrain from activities that harm the Website’s security or other users.</p>
                    </li>
                    <li>
                        <p>Not engage in unsolicited advertising or misuse user information.</p>
                    </li>
                </ul>
                <h2 className='terms_subheading'>Suspension of User Access and Activity</h2>
                <p>The Company may limit, suspend, or terminate the User's access without notice if the User:</p>
                <ul>
                    <li>
                        <p>Breaches the Terms, Policy, or any applicable law.</p>
                    </li>
                    <li>
                        <p>Provides incorrect or incomplete information.</p>
                    </li>
                    <li>
                        <p>Causes harm or potential loss to other users or the Company.</p>
                    </li>
                </ul>
                <h2 className='terms_subheading'>Indemnity and Limitations</h2>
                <p>The User agrees to indemnify the Company, its employees, and agents against any claims, damages, or losses arising from the User's actions or inactions. The Company is not liable for any indirect, incidental, or consequential damages resulting from the User's use of the Website.</p>
                <h2>Intellectual Property Rights</h2>
                <p>All trademarks, logos, and content on the Website are owned by the Company. Users must not use these without prior written permission. The Company is not responsible for third-party content but may remove any content violating the Terms.</p>
                <h2>Disclaimer of Warranties and Liabilities</h2>
                <ul>
                    <li>
                        <p><b>General Disclaimer: </b>All services are provided "as is" without any express or implied warranties.</p>
                    </li>
                    <li>
                        <p><b>No Warranty on Availability: </b>The Company does not guarantee that the Website will always be available or error-free.</p>
                    </li>
                    <li>
                        <p><b>User Discretion: </b>Users are responsible for verifying property details and using their judgment before making transactions.</p>
                    </li>
                </ul>
                <h2 className='terms_subheading'>Third Party Links</h2>
                <ul>
                    <li>
                        <p><b>No Control or Endorsement: </b>The Company is not responsible for third-party sites linked through the Website and does not endorse their content or services.</p>
                    </li>
                    <li>
                        <p><b>User Responsibility: </b>Users interact with third-party services at their own risk, and the Company is not liable for any losses arising from such interactions.</p>
                    </li>
                    <li>
                        <p><b>Social Networking Services: </b>The use of Social Networking Services is governed by their own privacy policies, and the Company is not liable for their actions.</p>
                    </li>
                </ul>
                <h2 className='terms_subheading'>Miscellaneous Provisions</h2>
                <ul>
                    <li>
                        <p><b>Entire Agreement: </b>These Terms and the Policy constitute the entire agreement.</p>
                    </li>
                    <li>
                        <p><b>Waiver: </b>Failure to enforce any provision does not waive the right to enforce it later.</p>
                    </li>
                    <li>
                        <p><b>Severability: </b>Invalid provisions do not affect the validity of the remaining provisions.</p>
                    </li>
                    <li>
                        <p><b>Grievances: </b>Users can report violations to support@bestpropdeal.com.</p>
                    </li>
                    <li>
                        <p><b>Assignment: </b>The Company may assign its rights; Users may not without consent.</p>
                    </li>
                </ul>
            </div>
            <Footer />
        </>
    );
};

export default TermsAndConditions;